import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {ProductApi} from '../services/ProductApi';
import {IProductDTO, IPropsInjectedByViewerScript} from '../types/app-types';
import {
  ADD_TO_CART_FEDOPS_INTERACTION,
  ADD_TO_CART_TRACK_EVENT,
  originPage,
  PUBLIC_DATA_KEYS,
  TRACK_EVENT_COLLECTION,
  translationPath,
} from '../constants';
import {getTranslations, isWorker} from '@wix/wixstores-client-core/dist/es/src/viewer-script/utils';
import {IControllerConfig} from '@wix/native-components-infra/dist/es/src/types/types';
import {MultilingualService} from '@wix/wixstores-client-core/dist/es/src/multilingualService/multilingualService';
import {CartActions} from '@wix/wixstores-client-core/dist/es/src/cart-actions/cartActions';
import {PubSubManager} from '@wix/wixstores-client-core/dist/es/src/pub-sub-manager/pubSubManager';
import {APP_DEFINITION_ID, BiButtonActionType, PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {AddToCartActionOption} from '@wix/wixstores-client-core/dist/src/constants';
import {ProductActions} from '@wix/wixstores-client-core/dist/es/src/product-actions/ProductActions';

export class AddToCartStore {
  private readonly productApi: ProductApi;
  private product: IProductDTO;
  private translations;
  private multilingualService: MultilingualService;
  private shouldReportFedops: boolean = true;
  private readonly cartActions: CartActions;
  private readonly pubSubManager: PubSubManager;
  private readonly productActions: ProductActions;
  private readonly fedopsLogger;

  constructor(
    private readonly publicData: IControllerConfig['publicData'],
    private readonly setProps: Function,
    private readonly siteStore: SiteStore,
    private readonly externalId: string,
    private readonly reportError: (e) => any,
    private readonly compId: string,
    private readonly type: string
  ) {
    this.productApi = new ProductApi(this.siteStore);
    this.pubSubManager = new PubSubManager(this.siteStore.pubSub);
    this.cartActions = new CartActions(this.siteStore, this.pubSubManager, originPage, PageMap.CART);
    this.productActions = new ProductActions(this.siteStore);
    const fedopsLoggerFactory = this.siteStore.platformServices.fedOpsLoggerFactory;
    this.fedopsLogger = fedopsLoggerFactory.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: this.type,
    });

    if (isWorker()) {
      this.fedopsLogger.appLoadStarted();
    }
  }

  public async setInitialState(): Promise<void> {
    const productId = this.publicData.COMPONENT && this.publicData.COMPONENT[PUBLIC_DATA_KEYS.PRODUCT_ID];
    const isDefaultProduct: boolean = !productId;
    const productQueryPromise: any = () =>
      isDefaultProduct
        ? this.productApi.getDefaultProduct(this.externalId)
        : this.productApi.getProductById(this.externalId, productId);

    const [translations, {data}] = await Promise.all([
      getTranslations(translationPath(this.siteStore.baseUrls.addToCartBaseUrl, this.siteStore.locale)),
      productQueryPromise(),
    ]).catch(this.reportError);

    this.translations = translations;

    /* istanbul ignore next: hard to test it */
    this.multilingualService = new MultilingualService(
      this.publicData.COMPONENT || {},
      data.appSettings.widgetSettings,
      this.siteStore.getMultiLangFields(),
      this.siteStore.locale
    );

    this.product = isDefaultProduct ? data.catalog.products.list[0] : (this.product = data.catalog.product);
    const propsToInject = this.product ? this.getPropsToInject() : this.getEmptyStatePropsToInject();
    this.setProps(propsToInject);

    if (this.siteStore.isSSR()) {
      this.fedopsLogger.appLoaded();
    }
  }

  public updateState(newPublicData: IControllerConfig['publicData'] & {appSettings?: any}): void {
    this.updatePublicData(newPublicData);
    this.multilingualService.setPublicData(this.publicData.COMPONENT);
    this.multilingualService.setWidgetSettings(newPublicData.appSettings);

    const propsToInject = this.product ? this.getPropsToInject() : this.getEmptyStatePropsToInject();
    this.setProps({
      ...propsToInject,
    });
  }

  private getInjectedFunctions() {
    return {
      handleAddToCart: this.handleAddToCart.bind(this),
      onAppLoaded: this.onAppLoaded.bind(this),
    };
  }

  private getPropsToInject(): IPropsInjectedByViewerScript {
    return {
      shouldDisableButton: this.isButtonDisabled(),
      buttonText: this.getButtonText(),
      ...this.getInjectedFunctions(),
    };
  }

  private getEmptyStatePropsToInject(): IPropsInjectedByViewerScript {
    const shouldDisableButton = !this.siteStore.isEditorMode();
    return {
      shouldDisableButton,
      buttonText:
        this.multilingualService.get(PUBLIC_DATA_KEYS.BUTTON_TEXT) || this.translations['addPanel.addToCart.button'],
      ...this.getInjectedFunctions(),
    };
  }

  private async handleAddToCart(): Promise<any> {
    this.fedopsLogger.interactionStarted(ADD_TO_CART_FEDOPS_INTERACTION);
    const QUANTITY = 1;

    if (this.product.hasOptions || this.product.customTextFields.length) {
      this.openQuickView();
      return;
    }
    this.trackAddToCart();
    this.reportAddToCartBI();
    await this.cartActions.addToCart(this.product.id, [], QUANTITY, [], AddToCartActionOption.MINI_CART);
    this.fedopsLogger.interactionEnded(ADD_TO_CART_FEDOPS_INTERACTION);
  }

  private openQuickView() {
    // tslint:disable-next-line no-floating-promises
    this.siteStore.biLogger.clickAddToCartWithOptionsSf({
      appName: 'addToCartApp',
      origin: originPage,
      hasOptions: true,
      productId: this.product.id,
      productType: this.product.productType,
      navigationClick: this.siteStore.isMobile() ? 'product-page' : 'quick-view',
    });

    // tslint:disable-next-line no-floating-promises
    this.productActions
      .quickViewProduct(this.product.id, originPage, this.product.urlPart, this.compId, this.externalId)
      .then(() => this.fedopsLogger.interactionEnded(ADD_TO_CART_FEDOPS_INTERACTION));
  }

  private updatePublicData(newPublicData: IControllerConfig['publicData']) {
    Object.keys(newPublicData.COMPONENT).forEach(key => {
      this.publicData.COMPONENT[key] = newPublicData.COMPONENT[key];
    });
  }

  private isButtonDisabled(): boolean {
    const isOutOfStock = !this.product.isInStock;
    const showContactSeller = this.product.price === 0;
    return !this.siteStore.isEditorMode() && (isOutOfStock || showContactSeller);
  }

  private getButtonText(): string {
    const isOutOfStock = !this.product.isInStock;
    const showContactSeller = this.product.price === 0;
    let buttonText =
      this.multilingualService.get(PUBLIC_DATA_KEYS.BUTTON_TEXT) || this.translations['addPanel.addToCart.button'];
    if (this.siteStore.isEditorMode()) {
      return buttonText;
    }
    if (showContactSeller) {
      buttonText = this.translations['addPanel.contactSeller.button'];
    } else if (isOutOfStock) {
      buttonText = this.translations['addPanel.outOfStock.button'];
    }
    return buttonText;
  }

  private trackAddToCart(): void {
    const quantity = 1;
    this.siteStore.windowApis.trackEvent(ADD_TO_CART_TRACK_EVENT, {
      appDefId: APP_DEFINITION_ID,
      category: TRACK_EVENT_COLLECTION,
      origin: 'Stores',
      id: this.product.id,
      name: this.product.name,
      price: this.product.comparePrice || this.product.price,
      currency: this.siteStore.currency,
      quantity,
      type: this.product.productType,
      sku: this.product.sku,
    });
  }

  private reportAddToCartBI(): void {
    const shouldNavigateToCart = this.cartActions.shouldNavigateToCart();
    const quantity = 1;

    const eventData = {
      buttonType: BiButtonActionType.AddToCart,
      appName: 'addToCartApp',
      hasOptions: this.product.hasOptions,
      productId: this.product.id,
      productType: this.product.productType,
      origin: originPage,
      isNavigateCart: shouldNavigateToCart,
      navigationClick: !shouldNavigateToCart ? 'mini-cart' : 'cart',
      quantity,
    };

    //tslint:disable-next-line no-floating-promises
    this.siteStore.biLogger.checkoutPageLoadSf(eventData);
  }

  public onAppLoaded(): void {
    /* istanbul ignore next: hard to test it */
    if (this.shouldReportFedops) {
      this.fedopsLogger.appLoaded();
      this.shouldReportFedops = false;
    }
  }
}
